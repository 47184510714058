import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
//import history from 'components/Routing/history';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PeopleIcon from '@material-ui/icons/People';
import BuildIcon from '@material-ui/icons/Build';
import HomeIcon from '@material-ui/icons/Home';
import SubscriptionsIcon from '@material-ui/icons/AttachMoney';
import PlansIcon from '@material-ui/icons/Payment';
import ProductsIcon from '@material-ui/icons/ListAlt';
import StatisticsIcon from '@material-ui/icons/Assessment';
import AppsIcon from '@material-ui/icons/Apps';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import TeamIcon from '@material-ui/icons/SupervisedUserCircle';
import AppleIcon from '@material-ui/icons/Apple';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import { getAuthenticated } from 'store/selectors/App';

// https://material-ui.com/components/tree-view/#gmail-clone

const styles = theme => ({
  root: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  nestedIcon: {
    minWidth: theme.spacing(5), // = 8 * 5
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:active': {
      color: theme.palette.text.primary,
    },
  },
});

class MenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOperationsOpen: true,
      isStatisticsOpen: true,
      isApplicationsOpen: true,
      isExportOpen: true,
      selectedItem: '',
    };
  }

  componentDidMount() {
    //console.log('>>>>>>>>>>>>>>>>>>>>', this.props.history.location.pathname);
    if (this.props.history.location.pathname === '/') {
      this.setState({ selectedItem: 'home' });
    } else if (this.props.history.location.pathname === '/operations/users') {
      this.setState({ selectedItem: '/operations/users' });
    } else if (
      this.props.history.location.pathname === '/operations/subscriptions'
    ) {
      this.setState({ selectedItem: '/operations/subscriptions' });
    } else if (this.props.history.location.pathname === '/operations/plans') {
      this.setState({ selectedItem: '/operations/plans' });
    } else if (
      this.props.history.location.pathname === '/operations/products'
    ) {
      this.setState({ selectedItem: '/operations/products' });
    } else if (this.props.history.location.pathname === '/operations/teams') {
      this.setState({ selectedItem: '/operations/teams' });
    } else if (
      this.props.history.location.pathname === '/operations/organizations'
    ) {
      this.setState({ selectedItem: '/operations/organizations' });
    } /*else if (
      this.props.history.location.pathname === '/statistics/organizations'
    ) {
      this.setState({ selectedItem: '/statistics/organizations' });
    } */ else if (
      this.props.history.location.pathname === '/applications/ios'
    ) {
      this.setState({ selectedItem: '/applications/ios' });
    }
  }

  /*
  onClickOperations = () => {
    //history.push('/operations');
    this.props.history.push('/operations');
  };

  onClickStatistics = () => {
    //history.push('/statistics');
    this.props.history.push('/statistics');
  };
  */

  onMenuClick = e => {
    // https://github.com/mui-org/material-ui/issues/3311#issuecomment-483118661
    //console.log('>>>>>>>>>>>>>>', e.currentTarget.dataset.name);
    switch (e.currentTarget.dataset.name) {
      /*
      case 'home':
        this.props.history.push('/');
        break;
      */
      case 'operations':
        this.setState(state => ({ isOperationsOpen: !state.isOperationsOpen }));
        break;
      /*  
      case 'users':
        this.props.history.push('/users');
        break;
      case 'subscriptions':
        this.props.history.push('/subscriptions');
        break;
      case 'plans':
        this.props.history.push('/plans');
        break;
      case 'products':
        this.props.history.push('/products');
        break;
      case 'statistics':
        this.props.history.push('/statistics');
        break;
      */
      case 'statistics':
        this.setState(state => ({ isStatisticsOpen: !state.isStatisticsOpen }));
        break;
      case 'applications':
        this.setState(state => ({
          isApplicationsOpen: !state.isApplicationsOpen,
        }));
        break;
      case 'export':
        this.setState(state => ({
          isExportOpen: !state.isExportOpen,
        }));
        break;
      default:
        break;
    }
    if (
      e.currentTarget.dataset.name !== 'operations' &&
      e.currentTarget.dataset.name !== 'statistics' &&
      e.currentTarget.dataset.name !== 'applications' &&
      e.currentTarget.dataset.name !== 'export'
    ) {
      this.setState({ selectedItem: e.currentTarget.dataset.name });
    }
  };

  render() {
    if (!this.props.isAuthenticated) {
      return null;
    }

    const { classes } = this.props;
    return (
      <Drawer
        className={classes.root}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <Link to="/" className={classes.link}>
              <ListItem
                button
                key="home"
                data-name="home"
                selected={this.state.selectedItem === 'home'}
                onClick={this.onMenuClick}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>

            <Divider />

            <ListItem
              button
              key="operations"
              data-name="operations"
              onClick={this.onMenuClick}
            >
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Operations" />
              {this.state.isOperationsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={this.state.isOperationsOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <Link to="/operations/users" className={classes.link}>
                  <ListItem
                    button
                    key="/operations/users"
                    data-name="/operations/users"
                    selected={this.state.selectedItem === '/operations/users'}
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </Link>

                <Link to="/operations/teams" className={classes.link}>
                  <ListItem
                    button
                    key="/operations/teams"
                    data-name="/operations/teams"
                    selected={this.state.selectedItem === '/operations/teams'}
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Teams" />
                  </ListItem>
                </Link>

                <Link to="/operations/organizations" className={classes.link}>
                  <ListItem
                    button
                    key="/operations/organizations"
                    data-name="/operations/organizations"
                    selected={
                      this.state.selectedItem === '/operations/organizations'
                    }
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <TeamIcon />
                    </ListItemIcon>
                    <ListItemText primary="Organizations" />
                  </ListItem>
                </Link>

                <Link to="/operations/subscriptions" className={classes.link}>
                  <ListItem
                    button
                    key="/operations/subscriptions"
                    data-name="/operations/subscriptions"
                    selected={
                      this.state.selectedItem === '/operations/subscriptions'
                    }
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <SubscriptionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Subscriptions" />
                  </ListItem>
                </Link>

                <Link to="/operations/plans" className={classes.link}>
                  <ListItem
                    button
                    key="/operations/plans"
                    data-name="/operations/plans"
                    selected={this.state.selectedItem === '/operations/plans'}
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <PlansIcon />
                    </ListItemIcon>
                    <ListItemText primary="Plans" />
                  </ListItem>
                </Link>

                <Link to="/operations/products" className={classes.link}>
                  <ListItem
                    button
                    key="/operations/products"
                    data-name="/operations/products"
                    selected={
                      this.state.selectedItem === '/operations/products'
                    }
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <ProductsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>

            {/* <Link to="/statistics" className={classes.link}>
              <ListItem
                button
                key="statistics"
                data-name="statistics"
                selected={this.state.selectedItem === 'statistics'}
                onClick={this.onMenuClick}
              >
                <ListItemIcon>
                  <StatisticsIcon />
                </ListItemIcon>
                <ListItemText primary="Statistics" />
              </ListItem>
            </Link> */}
            {/* 
          
            <ListItem
              button
              key="statistics"
              data-name="statistics"
              onClick={this.onMenuClick}
            >
              <ListItemIcon>
                <StatisticsIcon />
              </ListItemIcon>
              <ListItemText primary="Statistics" />
              {this.state.isStatisticsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={this.state.isStatisticsOpen}
              timeout="auto"
              unmountOnExit
            >
              */}

            {/* <List component="div" disablePadding>
                <Link to="/statistics/organizations" className={classes.link}>
                  <ListItem
                    button
                    key="/statistics/organizations"
                    data-name="/statistics/organizations"
                    selected={
                      this.state.selectedItem === '/statistics/organizations'
                    }
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <TeamIcon />
                    </ListItemIcon>
                    <ListItemText primary="Organizations" />
                  </ListItem>
                </Link>
              </List> */}
            {/*
            </Collapse>
 */}
            <ListItem
              button
              key="applications"
              data-name="applications"
              onClick={this.onMenuClick}
            >
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Applications" />
              {this.state.isApplicationsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={this.state.isApplicationsOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <Link to="/applications/ios" className={classes.link}>
                  <ListItem
                    button
                    key="/applications/ios"
                    data-name="/applications/ios"
                    selected={this.state.selectedItem === '/applications/ios'}
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <AppleIcon />
                    </ListItemIcon>
                    <ListItemText primary="iOS" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>

            <ListItem
              button
              key="export"
              data-name="export"
              onClick={this.onMenuClick}
            >
              <ListItemIcon>
                <StatisticsIcon />
              </ListItemIcon>
              <ListItemText primary="Export" />
              {this.state.isExportOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.isExportOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/export/registrations" className={classes.link}>
                  <ListItem
                    button
                    key="/export/registrations"
                    data-name="/export/registrations"
                    selected={
                      this.state.selectedItem === '/export/registrations'
                    }
                    onClick={this.onMenuClick}
                    className={classes.nested}
                  >
                    <ListItemIcon className={classes.nestedIcon}>
                      <TeamIcon />
                    </ListItemIcon>
                    <ListItemText primary="Registrations" />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </List>
        </div>
      </Drawer>
    );
  }
}

//export default withStyles(styles)(MenuBar);

// https://stackoverflow.com/questions/51265319/using-compose-and-connect-together-in-react-js-redux
const mapStateToProps = createStructuredSelector({
  isAuthenticated: getAuthenticated,
});
export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(MenuBar);
