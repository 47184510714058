import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import withSuspense from './Suspense';

const AuthPage = lazy(() => import('components/pages/Auth'));
const HomePage = lazy(() => import('components/pages/Home'));
const OpUsersPage = lazy(() => import('components/pages/Operations/Users'));
const OpPlansPage = lazy(() => import('components/pages/Operations/Plans'));
const OpProductsPage = lazy(() =>
  import('components/pages/Operations/Products')
);
const OpSubscriptionsPage = lazy(() =>
  import('components/pages/Operations/Subscriptions')
);
const OpTeamsPage = lazy(() => import('components/pages/Operations/Teams'));
const OpOrganizationsPage = lazy(() =>
  import('components/pages/Operations/Organizations')
);
//const StatisticsPage = lazy(() => import('components/pages/Statistics'));
const StOrganizationsPage = lazy(() =>
  import('components/pages/Statistics/Organizations')
);
const IOSApplicationPage = lazy(() =>
  import('components/pages/Applications/IOS')
);
const ExportRegistartionsPage = lazy(() =>
  import('components/pages/Export/Registrations')
);
const Page404 = lazy(() => import('components/pages/404'));
/*
const OperationsPage = lazy(() => {
  return Promise.all([
    import('components/pages/Operations'),
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
const StatisticsPage = lazy(() => {
  return Promise.all([
    import('components/pages/Statistics'),
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});
*/

const Routing = () => (
  <Switch>
    <Route path="/login" component={withSuspense(AuthPage)} />
    <PrivateRoute exact path="/" component={withSuspense(HomePage)} />
    <PrivateRoute
      path="/operations/users"
      component={withSuspense(OpUsersPage)}
    />
    <PrivateRoute
      path="/operations/subscriptions"
      component={withSuspense(OpSubscriptionsPage)}
    />
    <PrivateRoute
      path="/operations/plans"
      component={withSuspense(OpPlansPage)}
    />
    <PrivateRoute
      path="/operations/products"
      component={withSuspense(OpProductsPage)}
    />
    <PrivateRoute
      path="/operations/teams"
      component={withSuspense(OpTeamsPage)}
    />
    <PrivateRoute
      path="/operations/organizations"
      component={withSuspense(OpOrganizationsPage)}
    />
    {/* <PrivateRoute path="/statistics" component={withSuspense(StatisticsPage)} /> */}
    {/* <PrivateRoute
      path="/statistics/organizations"
      component={withSuspense(StOrganizationsPage)}
    /> */}
    <PrivateRoute
      path="/applications/ios"
      component={withSuspense(IOSApplicationPage)}
    />
    <PrivateRoute
      path="/export/registrations"
      component={withSuspense(ExportRegistartionsPage)}
    />
    <Route component={withSuspense(Page404)} />
  </Switch>
);

export default Routing;
