import { store } from 'store';
import { getAuthenticated } from 'store/selectors/App';
import { setSessionExpired, goLogin } from 'store/actions/App';
import history from 'components/Routing/history';

const API_BASE_URL = window.location.origin + '/';

const postRequest = async (endpoint, data = {}, uploadFile = false) => {
  //const body = JSON.stringify(data);
  const url =
    //process.env.NODE_ENV === 'development' ? endpoint : API_BASE_URL + endpoint;
    API_BASE_URL + endpoint;

  try {
    const response = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: uploadFile ? data : JSON.stringify(data),
    });

    const hasBody =
      (response.headers.get('Content-Length') &&
        response.headers.get('Content-Length') > 0) ||
      response.headers.get('Content-Encoding');

    if (response.status >= 200 && response.status < 300) {
      return hasBody ? response.json() : true;
    } else {
      if (response.status === 401) {
        if (getAuthenticated(store.getState())) {
          store.dispatch(setSessionExpired(true));
        } else {
          store.dispatch(goLogin(history.location));
        }
      }

      let errMsg = response.statusText;
      if (hasBody) {
        try {
          errMsg = await response.json();
        } catch (err) {
          console.error('Error request body:', err);
        }
      }
      return Promise.reject(errMsg);
    }
  } catch (err) {
    console.error('Unknown network error:', err);
    return Promise.reject(err);
  }
};

export const testRequest = () => {
  //return postRequest('api/v1/test/ok');
  //return postRequest('api/v1/test/ok-2');
  //return postRequest('api/v1/test/error');
  //return postRequest('api/v1/test/error-2');
  return postRequest('api/v1/account/info');
};

export const login_TEST = (login, password) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        status: 'success',
        result: { userInfo: { id: 1, username: 'Vadim' } },
      });
      /*
      resolve({
        status: 'error',
        result: { errorInfo: 'User not found' },
      });
      */
    }, 1000);
  });
};

export const logout_TEST = () => {
  return Promise.resolve();
};

export const getAuthUser_TEST = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        status: 'success',
        result: { userInfo: { id: 1, username: 'Vadim' } },
      });
      /*
      resolve({
        status: 'error',
        result: { errorInfo: 'Not authenticated' },
      });
      */
    }, 1000);
  });
};

export const getAuthUser = () => postRequest('api/v1/account/info');

export const logout = () => postRequest('api/v1/account/logout');

export const login = (login, password, need_confirm = false) =>
  postRequest('api/v1/account/login', { login, password, need_confirm });

export const confirmLogin = code =>
  postRequest('api/v1/account/confirm-login', { code });

export const changeUserSettings = data =>
  postRequest('api/v1/account/change-settings', { ...data });

export const changeUserAvatar = file =>
  postRequest('api/v1/account/change-avatar', file, true);

export const findUser = data => postRequest('api/v1/user/search', { ...data });

export const deleteUser = data =>
  postRequest('api/v1/user/delete', { ...data });

export const getUserInfo = data => postRequest('api/v1/user/info', { ...data });

export const getAllProducts = () => postRequest('api/v1/product/get-all');

export const getProductInfo = data =>
  postRequest('api/v1/product/info', { ...data });

export const createProduct = data =>
  postRequest('api/v1/product/create', { ...data });

export const updateProduct = data =>
  postRequest('api/v1/product/update', { ...data });

export const getPrices = product_id =>
  postRequest('api/v1/product/get-prices', { product_id });

export const createPrice = data =>
  postRequest('api/v1/price/create', { ...data });

export const updatePrice = data =>
  postRequest('api/v1/price/update', { ...data });

export const getAllPlans = () => postRequest('api/v1/plan/get-all');

export const getPlanInfo = data => postRequest('api/v1/plan/info', { ...data });

export const createPlan = data =>
  postRequest('api/v1/plan/create', { ...data });

export const updatePlan = data =>
  postRequest('api/v1/plan/update', { ...data });

export const getUserSubscriptions = data =>
  postRequest('api/v1/user/get-subscriptions', { ...data });

export const findSubscription = data =>
  postRequest('api/v1/subscription/search', { ...data });

export const getSubscriptionInfo = data =>
  postRequest('api/v1/subscription/info', { ...data });

export const createSubscription = data =>
  postRequest('api/v1/subscription/create', { ...data });

export const updateSubscription = data =>
  postRequest('api/v1/subscription/update', { ...data });

export const findOrganization = data =>
  postRequest('api/v1/organization/search', { ...data });

export const getOrganizationInfo = data =>
  postRequest('api/v1/organization/info', { ...data });

export const updateOrganization = data =>
  postRequest('api/v1/organization/update', { ...data });

export const findTeam = data => postRequest('api/v1/team/search', { ...data });

export const getTeamInfo = data => postRequest('api/v1/team/info', { ...data });

export const updateTeam = data =>
  postRequest('api/v1/team/update', { ...data });

export const getDashboardInfo = data =>
  postRequest('api/v1/stat/dashboard', { ...data });

export const getOrganizationsStatisctics = () =>
  postRequest('api/v1/stat/organizations');

export const getOrganizationMembersStatisctics = data =>
  postRequest('api/v1/stat/organization/members', { ...data });

export const getOrganizationMemberListStatisctics = data =>
  postRequest('api/v1/stat/organization/members_info', { ...data });

export const getIOSAppVersionHistory = () =>
  postRequest('api/v1/mobile/ios/get_version_history');

export const addNewIOSAppVersion = data =>
  postRequest('api/v1/mobile/ios/set_version', { ...data });

export const getExportRegistrationsInfo = () =>
  postRequest('api/v1/export/registrations_info');

export const exportRegistrationsInfo = data =>
  postRequest('api/v1/export/registrations_export', { ...data });
